import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import {
  CategoryName,
  CreatedAt,
  DetailContainer,
  FlexWrap,
  H1,
  TagsList,
  Thumbnail,
} from "../utils"

export const pageQuery = graphql`
  query ($slug: String) {
    contentfulPosts(slug: { eq: $slug }) {
      title
      tags {
        id
        name
        tagSlug
      }
      thumbnail {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      createdAt(formatString: "YYYY-MM-DD")
      content {
        childMarkdownRemark {
          html
        }
      }
      slug
    }
  }
`

const Post = ({ data }) => {
  const { title, createdAt, thumbnail, content, tags } = data.contentfulPosts
  return (
    <DetailContainer>
      <FlexWrap>
        <CategoryName>Blog</CategoryName>
        <CreatedAt>{createdAt}</CreatedAt>
      </FlexWrap>
      <H1>{title}</H1>
      <TagsList>
        {tags.map(node => {
          return (
            <li key={node.id}>
              <Link to={`/tag/${node.tagSlug}`}>#{node.name}</Link>
            </li>
          )
        })}
      </TagsList>
      <Thumbnail>
        <GatsbyImage image={getImage(thumbnail)}></GatsbyImage>
      </Thumbnail>
      <div
        dangerouslySetInnerHTML={{
          __html: content.childMarkdownRemark.html,
        }}
      />
    </DetailContainer>
  )
}

export default Post
