import styled from "styled-components"

export const Container = styled.div`
  padding: 100px ${({ theme }) => theme.width.archive};
`
export const DetailContainer = styled.div`
  padding: 100px ${({ theme }) => theme.width.single};
`
export const ContainerCol2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 240px;
  column-gap: ${({ theme }) => theme.spacing[3]};
  padding: 100px ${({ theme }) => theme.width.archive};
`
