import styled from "styled-components"

export const H1 = styled.h1`
  margin: ${({ theme }) => theme.spacing[3]} 0
    ${({ theme }) => theme.spacing[4]};
  font-size: ${({ theme }) => theme.fontSize["3xl"]};
`
export const H2 = styled.h2.attrs(props => ({
  mt: props.mt || "0",
}))`
  margin-top: ${props => props.mt};
  font-size: ${({ theme }) => theme.fontSize["3xl"]};
  font-family: ${({ theme }) => theme.font.head};
  font-weight: 700;
`
export const EnH2 = styled.h2.attrs(props => ({
  mt: props.mt || "0",
}))`
  margin-top: ${props => props.mt};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  font-size: ${({ theme }) => theme.fontSize["3xl"]};
  font-family: ${({ theme }) => theme.font.display};
  font-weight: 700;
  text-align: center;
  &::first-letter {
    color: ${({ theme }) => theme.colors.primary};
  }
`
export const Title = styled.h2`
  margin-top: ${({ theme }) => theme.spacing[1]};
  font-size: ${({ theme }) => theme.fontSize["base"]};
  font-family: ${({ theme }) => theme.font.body};
  font-weight: 700;
`
export const SubTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSize["xl"]};
  font-family: ${({ theme }) => theme.font.display};
  font-weight: 700;
`
export const CategoryName = styled.h3`
  font-size: ${({ theme }) => theme.fontSize["lg"]};
  font-family: ${({ theme }) => theme.font.head};
  font-weight: 700;
`

export const CreatedAt = styled.p`
  margin-left: ${({ theme }) => theme.spacing[4]};
  font-family: ${({ theme }) => theme.font.mono};
  font-size: ${({ theme }) => theme.fontSize["xs"]};
  font-weight: bold;
  letter-spacing: 0.1em;
`
