import styled from "styled-components"

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.spacing[1]} 0.25rem;
  background: ${({ theme }) => theme.colors.gray[100]};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  a {
    display: block;
    padding: 0.25rem 0.5rem;
  }
`
