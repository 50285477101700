import styled from "styled-components"

export const Column2 = styled.div.attrs(props => ({
  margin: props.mt || "0", //sizeとして受け取りmappingしている
  padding: props.size || "0", //sizeとして受け取りmappingしている
  //共通部分はこの下に書く
}))`
  margin-top: ${props => props.margin}; //上で定義したmarginを受け取る
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme }) => theme.spacing[4]};
`
export const Column3 = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing[5]} ${({ theme }) => theme.spacing[4]};
  overflow: visible;
  @media ${({ theme }) => theme.break.tb} {
    grid-template-columns: repeat(3, 1fr);
  }
`
